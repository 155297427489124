"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const breakpoints_1 = require("./breakpoints");
const color_1 = require("./color");
const BORDER_WIDTH_THIN = 1;
const BORDER_WIDTH_THICK = 2;
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_BOOK = 500;
const FONT_WEIGHT_MEDIUM = 600;
const FONT_WEIGHT_BOLD = 700;
const TRANSITION_DURATION = 0.3;
const TRANSITION_DURATION_SLOW = 0.6;
const TRANSITION_EASING = 'cubic-bezier(0, 0.8, 0.2, 1)';
const color = {
    border: color_1.Color.DARK_MEDIUM_GREY,
    borderLight: color_1.Color.MEDIUM_GREY,
    borderDark: color_1.Color.DARKEST_MEDIUM_GREY,
    cta: color_1.Color.GREEN,
    primary: color_1.Color.BLUE,
};
exports.theme = {
    animation: {
        duration: TRANSITION_DURATION,
        durationSlow: TRANSITION_DURATION_SLOW,
        easing: TRANSITION_EASING,
        transition: `${TRANSITION_DURATION}s ${TRANSITION_EASING}`,
        transitionSlow: `${TRANSITION_DURATION_SLOW}s ${TRANSITION_EASING}`,
    },
    elements: {
        page_header: 72,
        avatar: {
            size: {
                xs: 20,
                sm: 24,
                md: 32,
                lg: 40,
                xl: 64,
                xxl: 80,
            },
        },
        icon: {
            size: {
                xs: 12,
                sm: 16,
                md: 20,
                'md-plus': 24,
                lg: 28,
                xl: 40,
            },
        },
    },
    border: {
        radius: {
            normal: 6,
            semi: 8,
            rounded: 12,
            small: 4,
        },
        style: {
            thin: `${BORDER_WIDTH_THIN}px solid ${color.border}`,
            thinLight: `${BORDER_WIDTH_THIN}px solid ${color.borderLight}`,
            thinMedium: `${BORDER_WIDTH_THIN}px solid ${color_1.Color.LIGHT_DARK_MEDIUM_GREY}`,
            thinDark: `${BORDER_WIDTH_THIN}px solid ${color.borderDark}`,
            dashed: `${BORDER_WIDTH_THICK}px dashed ${color.border}`,
            blueInline: `6px solid ${color.primary}`,
        },
        width: {
            thin: BORDER_WIDTH_THIN,
            thick: BORDER_WIDTH_THICK,
        },
    },
    boxShadow: {
        elevated: '0px 4px 4px 0px rgba(6, 39, 84, 0.08), 0px 4px 8px 0px rgba(6, 39, 84, 0.05)',
        normal: '0px 1px 1px 0px rgba(6, 39, 84, 0.08), 0px 1px 2px 0px rgba(6, 39, 84, 0.05)',
    },
    breakpoints: [breakpoints_1.breakpoints.md],
    color,
    font: {
        family: 'CircularStd, "Helvetica Neue", Helvetica, Arial, sans-serif',
        size: {
            0: 48,
            1: 32,
            2: 24,
            3: 20,
            4: 18,
            5: 16,
            5.5: 15,
            6: 14,
            6.5: 13,
        },
        weight: {
            legacy: {
                300: FONT_WEIGHT_BOOK,
                500: FONT_WEIGHT_BOOK,
                600: FONT_WEIGHT_BOLD,
            },
            regular: FONT_WEIGHT_REGULAR,
            book: FONT_WEIGHT_BOOK,
            medium: FONT_WEIGHT_MEDIUM,
            // TODO(rb): replace this with medium in the future?
            bold: FONT_WEIGHT_BOLD,
        },
    },
    lineHeight: {
        normal: 1.2,
        relaxed: 1.4,
    },
    padding: {
        button: {
            smallest: {
                x: 6,
                y: 4,
            },
            smaller: {
                x: 10,
                y: 6,
            },
            small: {
                x: 16,
                y: 8,
            },
            medium: {
                x: 20,
                y: 10,
            },
            large: {
                x: 20,
                y: 10,
            },
            larger: {
                x: 28,
                y: 10,
            },
            largest: {
                x: 40,
                y: 20,
            },
        },
        input: {
            x: 16,
            y: 10,
        },
    },
    /**
     * These values have been taken from the default styled-system spacing values,
     * with some intermediate values added.
     */
    spacing: {
        0: 0,
        0.5: 2,
        1: 4,
        1.5: 6,
        2: 8,
        2.5: 12,
        3: 16,
        3.5: 24,
        4: 32,
        4.5: 48,
        5: 64,
        5.5: 96,
        6: 128,
        6.5: 192,
        7: 256,
        7.5: 384,
        8: 512,
    },
    /**
     * @deprecated use theme.animation
     */
    transition: {
        ease: '0.3s ease',
        easeOut: '0.2s ease-out',
    },
    zIndex: {
        select: 20,
        actionbar: 30,
        modalClose: 100,
        popover: 300,
        hovercard: 301, // Hovercard should be above popover
        shiftPopover: 4,
        sidebar: 40,
        contentpagesidebar: 2,
        toast: 400,
        tooltip: 1,
    },
};
