"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftToDayjs = void 0;
const date_1 = require("@bemlo/date");
const timesToDayjs = (date, startTime, endTime) => {
    const start = (0, date_1.dayjsEn)(date).time(startTime);
    let end = (0, date_1.dayjsEn)(date).time(endTime);
    const isOvernight = end.isBefore(start) || end.isSame(start);
    if (isOvernight) {
        end = end.add(1, 'day');
    }
    return { start, end };
};
const shiftToDayjs = (shift) => {
    const dayjsShift = timesToDayjs(shift.date, shift.startTime, shift.endTime);
    if (!shift.onCallTime) {
        return {
            ...dayjsShift,
            onCallTime: null,
        };
    }
    const doesOnCallStartNextDay = shift.onCallTime.startTime < shift.startTime;
    const onCallDayjs = timesToDayjs(doesOnCallStartNextDay
        ? dayjsShift.start.add(1, 'day').format(date_1.ISO_DATE_FORMAT)
        : dayjsShift.start.format(date_1.ISO_DATE_FORMAT), shift.onCallTime.startTime, shift.onCallTime.endTime);
    return {
        ...dayjsShift,
        onCallTime: onCallDayjs,
    };
};
exports.shiftToDayjs = shiftToDayjs;
