"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScheduleShiftStatus = exports.useSelectedShifts = exports.useScheduleResource = exports.useSchedule = exports.ScheduleProvider = exports.ScheduleContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const utils_1 = require("@bemlo/utils");
const useUIShiftsFromSlots_1 = require("./useUIShiftsFromSlots");
Object.defineProperty(exports, "getScheduleShiftStatus", { enumerable: true, get: function () { return useUIShiftsFromSlots_1.getScheduleShiftStatus; } });
const initialState = {
    collapsedScheduleResources: [],
    enableEditing: false,
    enableSelect: false,
    setCollapsedScheduleResources: () => null,
    shiftPopover: undefined,
    availabilityPopover: undefined,
    shifts: [],
    availability: [],
    startDate: '',
    viewBookings: 'rows',
    week: null,
    selectedShifts: undefined,
    setSelectedShifts: undefined,
    onCreateDraftShift: () => undefined,
    onDeleteDraftShift: () => undefined,
    workingTimeDirectiveRuleSet: undefined,
};
exports.ScheduleContext = react_1.default.createContext(initialState);
const ScheduleProvider = (props) => {
    const [collapsedScheduleResources, setCollapsedScheduleResources] = (0, react_1.useState)([]);
    const draftShifts = props.enableEditing
        ? props.enableEditing.draftShifts
        : utils_1.EMPTY_FROZEN_ARRAY;
    const shifts = [...props.shifts, ...draftShifts];
    const availability = props.availability ?? [];
    return ((0, jsx_runtime_1.jsx)(exports.ScheduleContext.Provider, { value: {
            shiftPopover: props.shiftPopover,
            availabilityPopover: props.availabilityPopover,
            enableEditing: !!props.enableEditing,
            enableSelect: !!props.enableSelect,
            startDate: props.week.days[0].dateFormatted,
            week: props.week,
            shifts,
            availability,
            selectedShifts: (0, utils_1.isTruthy)(props.enableSelect)
                ? props.enableSelect.selectedShifts
                : undefined,
            collapsedScheduleResources,
            setCollapsedScheduleResources,
            setSelectedShifts: (0, utils_1.isTruthy)(props.enableSelect)
                ? props.enableSelect.setSelectedShifts
                : undefined,
            viewBookings: props.compactBookings ? 'compact' : 'rows',
            onCreateDraftShift: (0, utils_1.isTruthy)(props.enableEditing)
                ? props.enableEditing.onCreate
                : undefined,
            onDeleteDraftShift: (0, utils_1.isTruthy)(props.enableEditing)
                ? props.enableEditing.onDelete
                : undefined,
            workingTimeDirectiveRuleSet: props.workingTimeDirectiveRuleSet,
        }, children: props.children }));
};
exports.ScheduleProvider = ScheduleProvider;
const useSchedule = () => {
    const context = react_1.default.useContext(exports.ScheduleContext);
    if (context === undefined) {
        throw new Error('useSchedule must be used within a ScheduleContextProvider');
    }
    return context;
};
exports.useSchedule = useSchedule;
const useScheduleResource = (id) => {
    const { collapsedScheduleResources, setCollapsedScheduleResources } = useSchedule();
    const isOpen = !collapsedScheduleResources.includes(id);
    const toggleOpen = () => {
        setCollapsedScheduleResources((curr) => {
            if (isOpen)
                return [...curr, id];
            return curr.filter((toggledId) => toggledId !== id);
        });
    };
    return { isOpen, toggleOpen };
};
exports.useScheduleResource = useScheduleResource;
const useSelectedShifts = (ids) => {
    const { selectedShifts, setSelectedShifts, enableSelect } = useSchedule();
    const toggleSelected = () => {
        if (!enableSelect)
            return;
        setSelectedShifts?.((curr) => {
            if (ids.every((id) => curr.includes(id))) {
                return curr.filter((id) => !ids.includes(id));
            }
            return (0, lodash_1.uniq)([...curr, ...ids]);
        });
    };
    const isSelected = ids.some((id) => selectedShifts?.includes(id));
    return {
        isSelected,
        toggleSelected,
        enableSelect,
        someShiftsSelected: !!selectedShifts?.length,
        allShiftsSelected: ids.every((id) => selectedShifts?.includes(id)) && !!ids.length,
    };
};
exports.useSelectedShifts = useSelectedShifts;
