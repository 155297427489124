"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedVacancies = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const DraftShifts_1 = require("../components/DraftShifts");
const FixedVacanciesTitle_1 = require("../components/FixedVacanciesTitle");
const Row_1 = require("../components/Row");
const Shift_1 = require("../components/Shift");
const useEditShifts_1 = require("../useEditShifts");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const FixedVacancies = (props) => {
    const { week, shifts: allShifts, shiftPopover } = (0, useSchedule_1.useSchedule)();
    const { enableEditing, onCreateDraftShift, onDeleteDraftShift } = (0, useEditShifts_1.useEditShifts)(props.draftShiftInput);
    if (!week)
        return null;
    const shifts = (0, get_shifts_in_week_1.getShiftsInWeek)(week, allShifts.filter((0, by_filter_1.byCellFilter)(props)));
    const shiftsToDisplay = props.includeBooked
        ? shifts
        : shifts.filter(({ bookedCandidateId }) => !bookedCandidateId);
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(FixedVacanciesTitle_1.FixedVacanciesTitle, { shifts: enableEditing ? shiftsToDisplay : undefined, rowKey: props.rowKey }) }), week.days.map((day) => {
                const [draftShifts, shiftsOnDay] = (0, lodash_1.partition)(shiftsToDisplay.filter((shift) => shift.date === day.dateFormatted), ({ status }) => status === 'DRAFT');
                const hasShifts = !!draftShifts.length || !!shiftsOnDay.length;
                return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: day.disabled, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [shiftsOnDay.map((shift) => {
                                const isArchived = shift.status === 'ARCHIVED';
                                const isUrgent = shift.isUrgent && !isArchived;
                                if (!shift)
                                    return null;
                                return ((0, jsx_runtime_1.jsx)(Shift_1.Shift, { startTime: shift.startTime, endTime: shift.endTime, interestedCandidates: shift.interestedCandidates, popoverContent: shiftPopover, shiftIds: [shift.id], status: shift.status, isUrgent: isUrgent, unselectable: isArchived, onCallBadge: shift.onCallTime }, shift.id));
                            }), enableEditing && ((0, jsx_runtime_1.jsx)(DraftShifts_1.DraftShift, { showBadges: true, shifts: draftShifts, onClickNewShift: hasShifts
                                    ? undefined
                                    : props.draftShiftInput
                                        ? () => onCreateDraftShift(day.dateFormatted, shifts[0]?.scheduleSlotId)
                                        : props.onClickNewShiftType, onDeleteDraftShift: onDeleteDraftShift }))] }) }, day.dateFormatted));
            })] }));
};
exports.FixedVacancies = FixedVacancies;
